// import 'lib-flexible'
import { createApp } from 'vue'
import "./assets/index.js"; // icon
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import SvgIcon from '@/components/SvgIcon'// svg component
// register globally
createApp(App).use(store).use(router).component('svg-icon', SvgIcon).mount('#app')
