<template>
  <div class="home">
    <home />
  </div>
</template>

<script>
// @ is an alias to /src
import Home from "@/components/home.vue";
export default {
  name: 'HomeView',
  components: {
    Home
  },
}
</script>
<style lang="scss" scoped>
.home {
  background: #000;
  width: 100%; /* 将容器宽度设为视口宽度（viewport width）*/
  height: 100%; /* 将容器高度设为视口高度（viewport height）*/
}
</style>
