<template>
  <div class="app-ref" ref="appRef">
    <router-view />
  </div>
</template>
<style lang="scss">
html, body {
  height: 100%; /* 将HTML和body元素高度设为100% */
  margin: 0; /* 去除默认边距 */
  padding: 0; /* 去除默认内边距 */
  overflow: hidden;
}

html{
  font-size: 16px;
}
//body{ font-size: 1rem;background: #000;}
.app-ref{ font-size: 1rem;background: #000;}
</style>