<template>
  <div class="hello" ref="appRef">
    <div class="left">
      <div class="website-svg center">
        <svg-icon class-name="website" :icon-class="'website'"/>
      </div>
      <div class="data center">100% Fresh and Luscious</div>
      <div class="main-title center">
        <div>IMPORTERS AND DISTRIBUTORS</div>
        <div>OF FRESH FRUITS AND</div>
        <div>VEGETABLES</div>
      </div>
      <div class="subtitle">
        Hualing Yinuo (Beijing) Trading Co. Ltd. is committed to providing wholesale import
        services for fresh fruits and vegetables to Saudi Arabia. We focus on meeting customer
        needs by supplyinng high-quality, fresh fruits and vegetables, along with flexible
        business solutions. Through a fast and reliable supply chain management, we ensure
        timely delivery of the desired products. Hualing Yinuo is dedicated to offering excellent
        products and services, becoming the preferred partner in the field of fruits and vegetables.
      </div>
      <div class="bottom">
        <div class="contract">Contact Us</div>
        <div class="flex-style">
          <div class="flex-style left-bottom center-part flex-style-phone">
            <div class="bottom-svg">
              <svg-icon class-name="email" :icon-class="'email'"/>
            </div>
            <div class="email-text bottom-svg">{{ emailText }}</div>
            <div>
              <svg-icon class-name="copy" @click="copyMethods(emailText)" :icon-class="'copy'"></svg-icon>
            </div>
          </div>
          <div class="flex-style bottom-phone center-part flex-style-phone">
            <div class="bottom-svg">
              <svg-icon class-name="phone" :icon-class="'phone'"/>
            </div>
            <div class="email-text bottom-svg">{{ phoneText }}</div>
            <div>
              <svg-icon class-name="copy" @click="copyMethods(phoneText)" :icon-class="'copy'"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <img src="/img/image-6.webp" alt="">
    </div>
  </div>
</template>

<script setup>
import {ElMessage} from 'element-plus';
import 'element-plus/es/components/message/style/css'
import {ref, onMounted, onUnmounted} from "vue"

const phoneText = ref("+966 546704570");
const emailText = ref("market@hualingyinuo.com");
import useClipboard from "vue-clipboard3";
const appRef = ref(null);
const {toClipboard} = useClipboard();
const toOrientation = (event) => {
  switch (event.target.screen.orientation.angle) {
    case 0:
      console.log(event.target.screen.orientation.angle, '0')
      appRef.value.style.width =  `${window.innerWidth}px`
      appRef.value.style.height =  `100vh`
      break;
    case 90:
      console.log(event.target.screen.orientation.angle, '90');
      appRef.value.style.width =  `${window.innerHeight}px`
      appRef.value.style.height =  `100vh`
      break;
    default:
      appRef.value.style.width =  `${window.innerWidth}px`
      appRef.value.style.height =  `${window.innerHeight}px`
      break;
  }
};
window.addEventListener("orientationchange", toOrientation);
const copyMethods = async (info) => {
  try {
    await toClipboard(info);
    ElMessage({
      message: 'Copy Success.',
      type: 'success',
    })
  } catch (e) {
    ElMessage({
      message: 'Warning, copy failed.',
      type: 'warning',
    })
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hello {
    //display: flex;
    //justify-content: end;
    width: 100%;
    height: 100vh;

    .flex-style-phone {
      display: flex;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .left {
      position: fixed;
      color: #fff;
      //box-sizing: border-box;
      left: 0;
      height: 100vh;
      //padding: 3.69rem 0 4.44rem 3.94rem;
      z-index: 10;

      .website-svg {
        box-sizing: border-box;
        padding: 3.7vh 0 4.1vh;
      }

      .left-bottom {
        justify-content: center;
      }

      .website {
        width: 8.7vh;
        height: 3.3vh;
        flex-shrink: 0;
        margin: 0 auto;
      }

      .data {
        color: #FFF;
        font-family: SF Pro;
        font-size: 1.7vh;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
        letter-spacing: 0.0175rem;
        margin-bottom: 1.4vh;
      }

      .main-title {
        width: 100%;
        color: #FFF;
        text-align: center;
        font-family: SF Pro;
        font-size: 2.2vh;
        font-style: normal;
        font-weight: 700;
        line-height: 3.6vh; /* 136.364% */
        letter-spacing: 0.0275rem;
        flex-direction: column;
        margin-bottom: 3.6vh;

        > div {
          text-align: center;
          width: 100%;
        }
      }

      .subtitle {
        width: 100%;
        height: 23.7vh;
        flex-shrink: 0;
        color: #FFF;
        text-align: center;
        font-family: SF Pro;
        display: flex;
        font-size: 1.3125rem;
        font-style: normal;
        font-weight: 274;
        //line-height: 2.5vh; /* 161.538% */
        letter-spacing: 0.01625rem;
        margin-bottom: 10.9vh;
        word-break: break-word;
      }

      .contract {
        display: none;
      }

      .bottom {
        position: fixed;
        bottom: 1.9vh;
        width: 100%;
        margin: 0 auto;

        .bottom-svg, .bottom-svg {
          margin-right: 0.4rem;
        }

        .email {
          //width: 1.125rem;
          height: 2.1vh;
          flex-shrink: 0;
        }

        .left-bottom {
          justify-content: center;
          align-items: center;
        }

        .email-text {
          color: #FFF;
          font-family: SF Pro;
          font-size: 1.375rem;
          font-style: normal;
          font-weight: 274;
          line-height: 4vh; /* 242.857% */
          letter-spacing: 0.0175rem;
        }

        .bottom-phone {
          justify-content: center;
          align-items: center;
        }
      }
    }

    > .right {
      position: fixed;
      left: 0;
      width: 100%;
      height: 49.5vh;
      flex-shrink: 0;
      bottom: 6.2%;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .hello {
    display: flex;
    justify-content: end;
    width: 100%;
    height: 100vh;
    .center-part {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .flex-style {
      display: flex;
    }

    .left-bottom {
      margin-right: 4.7vh;
    }

    > .left {
      position: fixed;
      color: #fff;
      box-sizing: border-box;
      left: 0;
      height: 100vh;
      padding: 7vh 0 8.4vh 7.4vh;
      z-index: 10;

      .website-svg {
        width: 23.7%;
        margin-bottom: 4.5vh;
      }

      .website {
        width: 14.9vh;
        height: 5.7vh;
        flex-shrink: 0;
      }

      .data {
        color: #FFF;
        font-family: SF Pro;
        font-size: 2.6vh;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
        letter-spacing: 0.035rem;
        margin-bottom: 3.8vh;
      }

      .main-title {
        color: #FFF;
        font-family: SF Pro;
        font-size: 4.7vh;
        font-style: normal;
        font-weight: 700;
        line-height: 5.9vh; /* 122.222% */
        letter-spacing: 0.0675rem;
        margin-bottom: 4vh;
      }

      .subtitle {
        color: #FFF;
        font-family: SF Pro;
        width: 97.5vh;
        font-size: 1.9vh;
        font-style: normal;
        font-weight: 274;
        //line-height: 4vh; /* 170% */
        letter-spacing: 0.025rem;
        margin-bottom: 10.9vh;
      }

      .contract {
        margin-bottom: 1.2vh;
      }

      .bottom {
        .bottom-svg {
          margin-right: 0.2rem;
        }

        .copy {
          display: none;
        }
      }

    }

    > .right {
      position: fixed;
      right: 0;
      height: 100%;
      width: 89%;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hello {
    //display: flex;
    //justify-content: end;
    width: 100%;
    height: 100vh;

    .flex-style-phone {
      display: flex;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .left {
      position: fixed;
      color: #fff;
      //box-sizing: border-box;
      left: 0;
      height: 100vh;
      //padding: 3.69rem 0 4.44rem 3.94rem;
      z-index: 10;

      .website-svg {
        box-sizing: border-box;
        padding: 3.7vh 0 4.1vh;
      }

      .left-bottom {
        justify-content: center;
      }

      .website {
        width: 8.7vh;
        height: 3.3vh;
        flex-shrink: 0;
        margin: 0 auto;
      }

      .data {
        color: #FFF;
        font-family: SF Pro;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
        letter-spacing: 0.0175rem;
        margin-bottom: 2.7vh;
      }

      .main-title {
        width: 100%;
        color: #FFF;
        text-align: center;
        font-family: SF Pro;
        font-size: 2.2vh;
        font-style: normal;
        font-weight: 700;
        line-height: 3.6vh; /* 136.364% */
        letter-spacing: 0.0275rem;
        flex-direction: column;
        margin-bottom: 3.6vh;

        > div {
          text-align: center;
          width: 100%;
        }
      }

      .subtitle {
        width: 100%;
        height: 23.7vh;
        flex-shrink: 0;
        color: #FFF;
        text-align: center;
        font-family: SF Pro;
        display: flex;
        font-size: 0.9125rem;
        font-style: normal;
        font-weight: 274;
        //line-height: 2.5vh; /* 161.538% */
        letter-spacing: 0.01625rem;
        margin-bottom: 10.9vh;
        word-break: break-word;
      }

      .contract {
        display: none;
      }

      .bottom {
        position: fixed;
        bottom: 1.9vh;
        width: 100%;
        margin: 0 auto;

        .bottom-svg, .bottom-svg {
          margin-right: 0.4rem;
        }

        .email {
          //width: 1.125rem;
          height: 3.6vh;
          flex-shrink: 0;
        }

        .left-bottom {
          justify-content: center;
          align-items: center;
        }

        .email-text {
          color: #FFF;
          font-family: SF Pro;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 274;
          line-height: 4vh; /* 242.857% */
          letter-spacing: 0.0175rem;
        }

        .bottom-phone {
          justify-content: center;
          align-items: center;
        }
      }
    }

    > .right {
      position: fixed;
      left: 0;
      width: 100%;
      height: 49.5vh;
      flex-shrink: 0;
      bottom: 2.3vh;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
